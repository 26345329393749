import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';

import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';
import BottomAction from '../sections/home3/BottomAction';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    // [BLOCKS.EMBEDDED_ASSET]: (node) => (<img className="w-100 mb-6" alt={node.data.target.title} src={node.data.target.file.url} />)
  },
};

const visibleHeight = 480;

const ContentShadow = styled.div`
  height: ${visibleHeight}px;
  margin-top: -${visibleHeight - 10}px;
  overflow: hidden;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 1)
  );
  position: relative;
  display: block;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 1)
  );
`;

const ContentPreview = styled.div`
  max-height: ${visibleHeight}px;
  overflow: hidden;
`;

const Goal = ({ data }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <div className="mb-7 d-inline-flex align-items-center flex-wrap">
                    <p className="bg-dark-green-op1 font-size-3 font-weight-medium badge mb-0 py-2 px-4 ml-8">
                      <span className="text-dark-green">Goal</span>
                    </p>
                  </div>
                  <h2 className="font-size-11 mb-8">
                    {data.contentfulGoal.title}
                  </h2>
                  {data.contentfulGoal.description && (
                    <p className="font-size-7 mb-7">
                      {data.contentfulGoal.description.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {data.contentfulGoal.body && (
              <ContentPreview className="px-3">
                <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                    <div className="pt-lg-17 pt-8 font-size-5 heading-default-color">
                      {renderRichText(data.contentfulGoal.body, options)}
                    </div>
                  </div>
                </div>
              </ContentPreview>
            )}

            <ContentShadow />
          </div>
        </div>
        <BottomAction
          title="Want to learn more?"
          description="A mentor can help with this topic &amp; many more. Join thousands of students &amp; pick a mentor today!"
        />
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query goal($contentful_id: String!) {
    contentfulGoal(contentful_id: { eq: $contentful_id }) {
      createdAt(formatString: "MM/DD/YY")
      title
      description {
        description
      }
      body {
        raw
        # references {
        #   ... on ContentfulAsset {
        #     contentful_id
        #     __typename
        #     title
        #     file {
        #       url
        #     }
        #   }
        # }
      }
    }
  }
`;

export default Goal;
